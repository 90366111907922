import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

const miod = {
  color: '#ca9502' 
}

const videoStyle = {
  position: 'relative',
  maxWidth: '100%'
}

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/szkola-animatora-2-2019.jpg'} alt={'plakat jereo 2019'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
            <div>
              <h2 className="miod"><strong style={miod}>DIECEZJALNA SZKOŁA ANIMATORA</strong> </h2>
              <p><em>SPOTKANIE DRUGIE - 23.11.2019 r. w Domu Miłosierdzia w Otmuchowie</em> </p>

              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/K9khyVu0DYk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/K9khyVu0DYk"></iframe>
              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/4sUUxQyaUCI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/4sUUxQyaUCI"></iframe>
              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/5Ffjv-platA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/5Ffjv-platA"></iframe>
              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/vIMVkkfGl7k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/vIMVkkfGl7k"></iframe>
              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/RD0BljerhoQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/RD0BljerhoQ"></iframe>
              <iframe style={videoStyle} width="560" height="315" src="https://www.youtube.com/embed/iFk2eXd3Gh0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="https://www.youtube.com/embed/iFk2eXd3Gh0"></iframe>

              <p className="miod"><strong style={miod}>Notatka ze spotkania:</strong></p>

              <p className="miod"><strong style={miod}>Godzina: 10:00</strong></p>
              <p>Rozpoczęcie spotkania - wzywanie Ducha Świętego śpiewem prowadzonym przez ks. Tomasza Szczecińskiego.</p>
              <p>Losowanie posług, koniecznych i przydatnych, aby dobrze i owocnie przeżyć spotkanie na Bożą Chwałę.</p>
              <p>Modlitwa do Ducha Świętego: uwielbienie i przywoływanie pieśniami oraz modlitwą spontaniczną, z prośbą o Jego obecność i prowadzenie w czasie spotkania.</p>
            
              <p className="miod"><strong style={miod}>Godzina: 10:30</strong></p>
              <p>Wykład ks. Artura Kasprzyckiego na temat psychiki animatora "Moje wnętrze,osobowość,charakter i dojrzałość"</p>
              
              <p>Główne aspekty wykładu:</p>
              <ul>
                <li>nierozerwalność i równowaga ciała i duszy w relacjach z ludźmi</li>
                <li>dojrzałość; poczucie własnej wartości, bezpieczeństwa, zaufanie do samego siebie</li>
                <li>wpływ wychowania w domu rodzinnym</li>
                <li>mechanizmy obronne</li>
                <li>radzenie sobie ze stresem</li>
                <li>uczucia i panowanie nad nimi</li>
                <li>jak być dobrym słuchaczem i mówcą</li>
               </ul>

               <p className="miod"><strong style={miod}>Godzina: 11:30</strong></p>
              <p>Krótka przerwa</p>

              <p className="miod"><strong style={miod}>Godzina: 11:40</strong></p>
              <p>Adoracja Najświętszego Sakramentu w kaplicy.</p>
              <p>Uwielbienie, dziękczynnienie, błogosławieństwo Pana naszego, Jezusa Chrystusa.</p>

              <p className="miod"><strong style={miod}>Godzina: 12:00</strong></p>
              <p>Eucharystia poprzedzona modlitwą "Anioł Pański".</p>
              <p>Homilię wygłosił ks.Tomasz Szczeciński na temat zmartwychwstania, daru, jaki otrzymaliśmy od Pana Boga dzięki śmierci Jego Umiłowanego Syna, Jezusa Chrystusa,w nawiązaniu do ewangelii Łk 20,27-40</p>

              <p className="miod"><strong style={miod}>Godzina: 13:00</strong></p>
              <p>Przerwa na pyszną zupę,którą uraczył nas gopodarz  Domu Miłosierdzia,ks.Tomasz</p>

              <p className="miod"><strong style={miod}>Godzina: 13:45</strong></p>
              <p>Warsztaty</p>
              <p>Głównym tematem była komunikacja międzyludzka;ja w relacjach osobowych-sztuka komunikacji.</p>
              <p>Warsztaty polegały na pracy indywidualnej(ze sobą), w grupach dwuosobowych(w cztery oczy) oraz wszystkich uczestników(na forum). Były one bardzo pomocne i uświadomily nam: czy umiemy słuchać, mówić i jak mówić dobrze trudną prawdę(upominać z miłością). Ks.Artur przeprowadził ćwiczenia uczące prawidłowego formułowania negatywnych komunikatów. Na zakończenie ks. Artur zadawał uczestnikom pytania dotyczące pracy w grupach.</p>

              <p className="miod"><strong style={miod}>Godzina: 15:00</strong></p>
              <p>Koronka do Miłosierdzia Bożego.</p>

              <p className="miod"><strong style={miod}>Godzina: 15:15</strong></p>
              <p>Informacje na temat kolejnego spotkania.</p>
              <p>Zadanie domowe: wypełnienie ankiety dotyczącej osobowości-mój temperament, charakter(kim jestem?)</p>
              <p>Indywidualne pytania do księży prowadzących spotkanie.</p>

              <p>Błogosławieństwo kończące spotkanie pełne Ducha Świetego.</p>

              <h2>Pliki do pobrania: </h2>

              <a href="/odnowa-animator-materialy/02_Osobowość.pdf" download> 02_Osobowość.pdf </a>
              <br />
              <a href="/odnowa-animator-materialy/03_Mechanizmy_obronne.pdf" download> 03_Mechanizmy_obronne.pdf </a>
              <br />
              <a href="/odnowa-animator-materialy/04_Lista uczuć.pdf" download> 04_Lista uczuć.pdf </a>
              <br />
              <a href="/odnowa-animator-materialy/05_Zasady_komunikacji.pdf" download> 05_Zasady_komunikacji.pdf </a>
              <br />
              <a href="/odnowa-animator-materialy/06_Konstruktywna_krytyka.pdf" download> 06_Konstruktywna_krytyka.pdf </a>
              <br />
              <a href="/odnowa-animator-materialy/07_Temperament.pdf" download> 07_Temperament.pdf </a>
              <br />

              <hr/>
              
              <img src={'/animator-2/1.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/2.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/3.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/4.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/5.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/6.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/7.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/8.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/9.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/10.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/11.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/12.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-2/13.jpg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />

              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
